<template>
  <div class="hotdetails">
    <!-- 头部 -->
    <app-header @getHeight="getHeight" :phone="phone" />

    <div class="max_width min_width min_height">
      <div
        class="flex align_center padtb_20"
        :style="{ 'margin-top': offsetHeight + 'px' }"
      >
        <div class="f838 size_14">当前位置：</div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            v-for="(item, i) in $route.meta.breadcrumb"
            :to="item.path ? { path: item.path } : ''"
            :key="i"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>

      <section class="flex align_start jus_content marb_30">
        <!-- 左边 -->
        <div class="width_30 pad_30 bgfff">
          <div>
            <div class="border_title" style="font-size: 16px">法律知识</div>

            <div class="flex align_center mart_20">
              <div class="width hot_details_search">
                <el-input placeholder="请输入关键词" v-model="keyword">
                  <i
                    slot="suffix"
                    class="el-icon-search"
                    @click="searchData"
                  ></i>
                </el-input>
              </div>
            </div>
          </div>

          <!-- 列表 -->
          <div class="mart_30">
            <div
              class="hotdetails_infinite"
              :class="{
                flex: hotList.length == 0,
                align_center: hotList.length == 0,
                jus_center: hotList.length == 0,
              }"
            >
              <div
                class="infinite-list-wrapper"
                style="overflow: auto; height: 100%"
                v-if="hotList.length > 0"
              >
                <ul
                  class="list"
                  v-infinite-scroll="infiniteData"
                  infinite-scroll-disabled="disabled"
                  :infinite-scroll-immediate="false"
                >
                  <li
                    v-for="(item, index) in hotList"
                    :key="index"
                    class="list-item padb_15 border_bottom mart_15"
                    :style="{ 'margin-top': index == 0 ? '0px' : '' }"
                    @click="setInfo(item.id)"
                  >
                    <div
                      class="size_15 ttt text_line2 font cursor"
                      :title="item.title"
                      :style="{ color: qid == item.id ? '#0D8EFF' : '' }"
                    >
                      {{ item.title }}
                    </div>
                    <div class="c61 size_12 mart_20 time_font mart_10">
                      发布时间：{{ item.create_time }}
                    </div>
                  </li>
                </ul>
                <p v-if="loading" class="text_center c61 mart_10">加载中...</p>
                <p v-if="noMore" class="text_center c61 mart_10">没有更多了</p>
              </div>

              <el-empty
                description="暂无数据"
                v-if="empty"
                :image-size="250"
              ></el-empty>
            </div>
          </div>
        </div>

        <!-- 右边 -->
        <div class="width_68 bgfff hotdetails_pad">
          <div class="flex column align_center" style="min-height: 860px">
            <div class="size_20 weight ttt">{{ info.title }}</div>
            <div class="mart_20 flex align_center jus_center">
              <div class="c61 size_16 marr_30">
                发布时间：{{ info.create_time }}
              </div>
              <div class="c61 size_16 marr_30">来源：{{ info.source }}</div>
              <div class="c61 size_16 marr_30">
                访问量：{{ info.click_num }}
              </div>
            </div>

            <div
              class="mart_20 size_14 html-txt fnf line_heihgt30"
              v-html="info.content"
            ></div>
          </div>
        </div>
      </section>
    </div>

    <el-backtop target=".hotdetails">
      <i class="el-icon-caret-top"></i>
    </el-backtop>

    <!-- 尾部 -->
    <app-footer @phone="getPhone" />
  </div>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";

export default {
  data() {
    return {
      keyword: "",
      loading: false,
      hotList: [],
      page: 1,
      limit: 15,
      // 总数
      count: 0,
      info: {}, // 详情
      arr: [],
      qid: "",
      empty: false,
      offsetHeight: 0,
      phone: "",
    };
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  computed: {
    noMore() {
      return this.arr.length < this.limit;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    // 附件下载
    download(url) {
      location.href = url;
    },
    getPhone(phone) {
      this.phone = phone;
    },
    // 获取头部高度
    getHeight(data) {
      this.offsetHeight = data;
    },
    searchData() {
      this.page = 1;
      this.hotList = [];

      this.getNewsPort();
    },
    // 热点动态
    getNewsPort() {
      let data = {
        type: 2,
        page: this.page,
        limit: this.limit,
        keyword: this.keyword,
      };

      this.$get("/cases_list", data).then((res) => {
        console.log(res);
        this.arr = res.result ? res.result : [];
        console.log();
        if (res.result) {
          this.hotList = this.hotList.concat(res.result);

          this.count = res.count;
          this.empty = false;

          if (!this.qid) {
            this.qid = this.hotList[0].id;
          }

          this.getInfoPort();
        } else {
          if (this.hotList.length > 0) {
            this.loading = false;
            return;
          }

          this.hotList = [];
          this.empty = true;
        }

        this.loading = false;
      });
    },
    // 滚动分页
    infiniteData() {
      this.loading = true;

      this.page += 1;

      this.getNewsPort();
    },
    // 详情
    getInfoPort() {
      let data = { id: this.qid, type: 2 };

      this.$get("/cases_list", data).then((res) => {
        let data = { id: this.qid };

        this.$get("/cases_info", data).then((ress) => {
          console.log(ress);
          this.info = ress.result;
        });
      });
    },
    // 点击左边展示详情
    setInfo(id) {
      this.qid = id;
      console.log(this.qid);
      this.getInfoPort();
    },
  },
  mounted() {
    console.log(this.$route.params.id);
    if (this.$route.params.id) {
      this.qid = this.$route.params.id;
    }

    this.getNewsPort();
  },
};
</script>

<style scoped>
.hotdetails .html-txt {
  word-break: break-all;
}
.hotdetails {
  height: 100vh;
  overflow-x: hidden;
}

.hot_details_search {
  background: #ffffff;
  border-radius: 6px;
}

.hot_details_search .el-input__inner {
  background-color: #f5f5f5 !important;
  border: none !important;
  border-radius: 25px !important;
}
.hot_details_search .el-icon-search {
  color: #0d8eff !important;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.hot_details_search .el-input__clear {
  height: 100% !important;
}

.hotdetails .el-input--suffix .el-input__inner {
  padding-right: 45px;
}
.hotdetails .el-input__suffix {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

.hotdetails_infinite {
  height: 750px;
}

.hotdetails_pad {
  padding: 30px 50px;
}

/*滚动条样式*/
.infinite-list-wrapper::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px !important;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}

.infinite-list-wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
}

.infinite-list-wrapper::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>
